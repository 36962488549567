.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-unset{
  background-color: unset !important;
}

.force-yellow{
  background-color: #fde047 !important
}

.force-green{
  background-color: #86efac !important
}

.red-row{
  background-color: #fca5a5 !important;
}

.gre-row{
  background-color: #bef264 !important;
}

.yel-row{
  background-color: #fcd34d !important;
}

.shortcuts-container {
  position: relative;
  z-index: 6;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  backdrop-filter: blur(10px);
}

.loading-container {
  z-index: 1031;
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; /* Full viewport height */
  background-color: rgba(59, 52, 56, 0.31); /* Light background */
}

.spinner-container {
  text-align: center;
}

.loading-text {
  opacity: 1;
  display: block;
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 700;
}